
    .row {
        margin-top:10px;
    }

    .users-custom-control{
        width: 49%;
      }

.btn-space {
  margin-right: 5px;
}

.button-custom {
  width: 100%;
}